<template>
  <a-modal
    :visible="visible"
    title="发行列表"
    width="80%"
    v-highlight
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="配置类型" prop="type">
                <a-select placeholder="请选择配置类型" style="width: 100%" v-model="queryParam.type" allowClear>
                  <a-select-option v-for="(item, index) in this.customDict.TalkEnum" :value="item.type"
                                   :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="名字" prop="name">
                <a-input v-model="queryParam.name" placeholder="请输入名字" allow-clear/>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="微信号" prop="wechat">
                  <a-input v-model="queryParam.wechat" placeholder="请输入微信号" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="是否认证" prop="isAuth">
                  <a-switch v-model="queryParam.isAuth" />{{ queryParam.isAuth ? '是' : '否' }}
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" />
          <a @click="handleBind(record)">
            <a-icon type="sure" />绑定头像
          </a>
        </span>
        <template slot="logo" slot-scope="text, record">
          <div>
            <img v-if="record.logo" :src="record.logo + '?x-oss-process=image/format,png'"  style="width:60px;height:62px;" @preview="handlePreview"/>
          </div>
        </template>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </a-modal>
</template>

<script>
import { listScriptConfig, delScriptConfig } from '@/api/script/scriptConfig'
import { tableMixin } from '@/store/table-mixin'
import { mapGetters } from 'vuex'

export default {
  name: 'ScriptConfigComponents',
  props: {
  },
  components: {
  },
  mixins: [tableMixin],
  data () {
    return {
      visible: false,
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        type: undefined,
        name: undefined,
        logo: undefined,
        wechat: undefined,
        isAuth: undefined,
        publishNum: undefined,
        saleNum: undefined,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '配置类型',
          dataIndex: 'typeName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '名字',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: 'logo图片',
          dataIndex: 'logo',
          scopedSlots: { customRender: 'logo' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '微信号',
          dataIndex: 'wechat',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '是否认证',
          dataIndex: 'isAuthName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '发行数',
          dataIndex: 'publishNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '销量',
          dataIndex: 'saleNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  methods: {
    handleOk() {
      this.visible = false
    },
    handleCancel() {
      this.visible = false
    },
    /** 查询剧本配置（作者/监制/发行）列表 */
    getList () {
      this.loading = true
      listScriptConfig(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    handlePreview(file) {
      this.previewAvatar = file.url || file.preview
      this.previewVisible = true
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        type: undefined,
        name: undefined,
        logo: undefined,
        wechat: undefined,
        isAuth: undefined,
        publishNum: undefined,
        saleNum: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          return delScriptConfig(ids)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel () {}
      })
    },
    /** 绑定按钮操作 */
    handleBind (row) {
      if (row.logo) {
        console.log('获取到的', row.logo)
        this.$emit('bindLogo', row.logo)
        this.visible = false
      } else {
        this.$message.warning('请选择有logo图片的数据进行绑定！')
      }
      // this.visible = false
      // this.$emit('select', ids)
      // this.$emit('selectname', scriptName)
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('script/script/config/export', {
            ...that.queryParam
          }, `script/config_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    },
    selectHandleChange(value) {
      this.queryParam.isAuth = value
    },
    open(sellers) {
      if (sellers.length > 0) {
        this.queryParam.ids = sellers
        this.getList()
        this.visible = true
      } else {
        this.$message.warning('请先选择发行再进行头像选择')
      }
    }
  }
}
</script>
